import aptA from '../assets/images/models/aptoa.png'
import aptB2 from '../assets/images/models/aptob2.png'
import aptB from '../assets/images/models/aptob.png'
import aptC from '../assets/images/models/aptoc.png'
import aptD from '../assets/images/models/aptod.png'
import aptE from '../assets/images/models/aptoe.png'
import aptF from '../assets/images/models/aptof.png'
import levelsA from '../assets/images/models/levels.svg'
import levelsB from '../assets/images/models/levelsB.svg'
import levelsC from '../assets/images/models/levelsC.svg'
import levelsD from '../assets/images/models/levelsD.svg'
import levelsE from '../assets/images/models/levelsE.svg'
import levelsF from '../assets/images/models/levelsF.svg'
import vistaAptA from '../assets/images/models/foto2.png'
import vistaAptB from '../assets/images/models/vistaAptB.png'
import vistaAptC from '../assets/images/models/vistaC.png'
import vistaAptD from '../assets/images/models/vistaD.png'
import vistaAptE from '../assets/images/models/vistaE.png'
import vistaAptF from '../assets/images/models/vistaF.png'
import lvlrenderA from '../assets/images/levels/AD.png'
import lvlrenderA1 from '../assets/images/levels/AD1.svg'
import lvlrenderA2 from '../assets/images/levels/AD2.svg'
import lvlrenderA3 from '../assets/images/levels/AD3.svg'

const apartamentos = [
    {
        name: 'A',
        rooms: '3',
        bathrooms: '2',
        parking: '2',
        store: '1',
        apartmentMeters: '80m²',
        parkingMeters: '25m²',
        storeMeters: '3m²',
        total: '108m²',
        renderApartment: aptA,
        level: levelsA,
        index: 0,
        niveles: '2',
        orientacion: ['NE - NORESTE', 'NO - NOROESTE', 'SE - SURESTE', 'SO - SUROESTE'],
        vista: vistaAptA,
        nivel:'2802',
        disponibilidad: true,
        renderLevel: lvlrenderA,
        renderRed: lvlrenderA1,
        renderWhite: lvlrenderA3,
        reserva: '0.00',
        cuota: '0.00',
        totalPrecio: '0.00',
    },
   /*  {
        name: 'B2',
        rooms: '2',
        bathrooms: '2',
        parking: '2',
        store: '1',
        apartmentMeters: '66m²',
        parkingMeters: '25m²',
        storeMeters: '3m²',
        total: '94m²',
        renderApartment: aptB2,
        level: levelsB,
        index: 1,
        niveles: ['14', '15', '16', '17', '18', '19', '20', '21', '22'],
        orientacion: ['NE - NORESTE', 'NO - NOROESTE', 'SE - SURESTE', 'SO - SUROESTE'],
        vista: vistaAptB,
        nivel:'2802',
        disponibilidad: true,
        renderLevel: lvlrenderA,
        renderRed: lvlrenderA1,
        renderWhite: lvlrenderA3,
        reserva: '0.00',
        cuota: '0.00',
        totalPrecio: '0.00',

    },
    {
        name: 'B3',
        rooms: '3',
        bathrooms: '2',
        parking: '2',
        store: '1',
        apartmentMeters: '66m²',
        parkingMeters: '25m²',
        storeMeters: '3m²',
        total: '94m²',
        renderApartment: aptB,
        level: levelsB,
        index: 2,
        niveles: ['14', '18', '19', '20', '22'],
        orientacion: ['NE - NORESTE', 'NO - NOROESTE', 'SE - SURESTE', 'SO - SUROESTE'],
        vista: vistaAptB,
        nivel:'2802',
        disponibilidad: true,
        renderLevel: lvlrenderA,
        renderRed: lvlrenderA1,
        renderWhite: lvlrenderA3,
        reserva: '0.00',
        cuota: '0.00',
        totalPrecio: '0.00',
    }, */
    {
        name: 'C',
        rooms: '2',
        bathrooms: '2',
        parking: '2',
        store: '1',
        apartmentMeters: '56m²',
        parkingMeters: '25m²',
        storeMeters: '3m²',
        total: '84m²',
        renderApartment: aptC,
        level: levelsC,
        index: 3,
        niveles: ['6', '7', '8', '9', '10', '11', '15', '16', '17', '18', '19', '20', '21', '24', '25', '26'],
        orientacion: ['NE - NORESTE', 'NO - NOROESTE', 'SE - SURESTE', 'SO - SUROESTE'],
        vista: vistaAptC,
        nivel:'2802',
        disponibilidad: true,
        renderLevel: lvlrenderA,
        renderRed: lvlrenderA1,
        renderWhite: lvlrenderA3,
        reserva: '0.00',
        cuota: '0.00',
        totalPrecio: '0.00',
    },
   /*  {
        name: 'D',
        rooms: '3',
        bathrooms: '2.5',
        parking: '2',
        store: '1',
        apartmentMeters: '112m²',
        parkingMeters: '25m²',
        storeMeters: '3m²',
        total: '140m²',
        renderApartment: aptD,
        level: levelsD,
        index: 4,
        niveles: ['14', '15', '16', '17', '18', '19', '20', '21', '22'],
        orientacion: ['NE - NORESTE', 'NO - NOROESTE', 'SE - SURESTE', 'SO - SUROESTE'],
        vista: vistaAptD,
        nivel:'2802',
        disponibilidad: true,
        renderLevel: lvlrenderA,
        renderRed: lvlrenderA1,
        renderWhite: lvlrenderA3,
        reserva: '0.00',
        cuota: '0.00',
        totalPrecio: '0.00',
    },
    {
        name: 'E',
        rooms: '3',
        bathrooms: '2',
        parking: '2',
        store: '1',
        apartmentMeters: '80m²',
        parkingMeters: '25m²',
        storeMeters: '3m²',
        total: '108m²',
        renderApartment: aptE,
        level: levelsE,
        index: 5,
        niveles: ['14', '15', '16', '17', '18', '19', '20', '21', '22'],
        orientacion: ['NE - NORESTE', 'NO - NOROESTE', 'SE - SURESTE', 'SO - SUROESTE'],
        vista: vistaAptE,
        nivel:'2802',
        disponibilidad: true,
        renderLevel: lvlrenderA,
        renderRed: lvlrenderA1,
        renderRed2: lvlrenderA2,
        renderWhite: lvlrenderA3,
        reserva: '0.00',
        cuota: '0.00',
        totalPrecio: '0.00',
    },
    {
        name: 'F',
        rooms: '3',
        bathrooms: '2',
        parking: '2',
        store: '1',
        apartmentMeters: '75m²',
        parkingMeters: '25m²',
        storeMeters: '3m²',
        total: '103m²',
        renderApartment: aptF,
        level: levelsF,
        index: 6,
        niveles: ['14', '15', '16', '17', '18', '19', '20', '21', '22'],
        orientacion: ['NE - NORESTE', 'NO - NOROESTE', 'SE - SURESTE', 'SO - SUROESTE'],
        vista: vistaAptF,
        nivel:'2802',
        disponibilidad: true,
        renderLevel: lvlrenderA,
        renderRed: lvlrenderA1,
        renderWhite: lvlrenderA3,
        reserva: '0.00',
        cuota: '0.00',
        totalPrecio: '0.00',
    }, */
  /*   {
        name: 'A',
        rooms: '3',
        bathrooms: '2',
        parking: '2',
        store: '1',
        apartmentMeters: '80m²',
        parkingMeters: '25m²',
        storeMeters: '3m²',
        total: '108m²',
        renderApartment: aptA,
        level: levelsA,
        index: 7,
        niveles: '3',
        orientacion: ['NE - NORESTE', 'NO - NOROESTE', 'SE - SURESTE', 'SO - SUROESTE'],
        vista: vistaAptA,
        nivel:'2802',
        disponibilidad: true,
        renderLevel: lvlrenderA,
        renderRed: lvlrenderA1,
        renderWhite: lvlrenderA3,
        reserva: '0.00',
        cuota: '0.00',
        totalPrecio: '0.00',
    },
        {
        name: 'A',
        rooms: '3',
        bathrooms: '2',
        parking: '2',
        store: '1',
        apartmentMeters: '80m²',
        parkingMeters: '25m²',
        storeMeters: '3m²',
        total: '108m²',
        renderApartment: aptA,
        level: levelsA,
        index: 8,
        niveles: '4',
        orientacion: ['NE - NORESTE', 'NO - NOROESTE', 'SE - SURESTE', 'SO - SUROESTE'],
        vista: vistaAptA,
        nivel:'2802',
        disponibilidad: true,
        renderLevel: lvlrenderA,
        renderRed: lvlrenderA1,
        renderWhite: lvlrenderA3,
        reserva: '0.00',
        cuota: '0.00',
        totalPrecio: '0.00',
    }, */
]

export default apartamentos