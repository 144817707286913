import React from "react";
import './styles.scss';
import logo from '../../assets/images/navbar/logo-altaire.svg'

const Loading = () => {
    return (
        <div className="row">
            <div className="col w-100 d-flex justify-content-center align-items-center loading-container flex-column">
                <img src={logo} alt="logo-altaire"/>
                <div class="spinner-border text-light mt-4" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>

    )
}

export default Loading

