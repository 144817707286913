import {
  withRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Suspense, lazy } from 'react'
import Loading from '../Loading/index'
import { useState, useEffect } from 'react';
import apartamentos from "../list";
import axios from 'axios';

const Navbar = lazy(() => import('../Navbar/index'));
const Home = lazy(() => import('../Home/index'));
const Models = lazy(() => import('../Models/index'));
const Amenities = lazy(() => import('../Amenities/index'));
const Location = lazy(() => import('../Location/index'));
const Contact = lazy(() => import('../Contact/index'));
const Footer = lazy(() => import('../Footer/index'));
const Home2 = lazy(() => import('../Home2/index'));
const Apartamento = lazy(() => import('../Apartamento/index'));
const Filter = lazy(() => import('../Filter/index'));
const Search = lazy(() => import('../Search/index'));
const Detail = lazy(() => import('../Detail/index'));
const Detail2 = lazy(() => import('../Detail2/index'));

/*
import Home from '../Home/index'
import Models from '../Models/index'
import Amenities from '../Amenities/index'
import Location from '../Location/index'
import Contact from '../Contact/index'
import Footer from '../Footer/index'
import Home2 from '../Home2/index'
import Apartamento from "../Apartamento";
import Loading from '../Loading/index'
*/



const HOME = (props) => (
  <div>
    <Navbar {...props}/>
    <Home {...props}/>
    <Home2 />
    <Amenities />
    <Filter {...props} />
    <Models />
    <Location />
    <Contact {...props}/>
    <Footer />
  </div>
)

const SEARCH = (props) => {
  return (
    <div>
      <Navbar {...props}/>
      <Search {...props} />
      <Footer />
    </div>
  )
}

const DETAIL = (props) => {
  return (
    <div>
      <Navbar {...props}/>
      <Detail {...props} />
      <Footer />
    </div>
  )
}

const DETAIL2 = (props) => {
  return (
    <div>
      <Navbar {...props}/>
      <Detail2 {...props} />
      <Footer />
    </div>
  )
}

const ContentHome = withRouter((props) => <HOME {...props} />);
const ContentSearch = withRouter((props) => <SEARCH {...props} />);
const ContentDetail = withRouter((props) => <DETAIL {...props} />);
const ContentDetail2 = withRouter((props) => <DETAIL2 {...props} />);


const App = () => {
  const [filter, setFilter] = useState('2');
  let inicial = () => {
    if (filter === '2') {
      return ('B2')
    }
    if (filter === '3') {
      return ('A')
    }
  }
  const [selector, setSelector] = useState('');
  const [btnstyle, setBtnstyle] = useState(false)
  const [btnAptstyle, setAptBtnstyle] = useState(false)
  const [btnAptstyle3, setbtnAptstyle3] = useState(false)
  const [numberLevel, setNumberLevel] = useState('2')
  const [apartments, setApartments] = useState([])
  const [selectedLevel, setSelectedLevel] = useState('')
  const [selectedType, setSelectedType] = useState('')
  const [selectedView, setSelectedView] = useState('')
  const [bandera, setBandera] = useState(false)
  const [whatsapp, setWhatsapp] = useState("")
  let apt = apartments.map((value) => value)
  //const selectedApartment = apt[selector]
  const [selectedApartment, setSelectapartment] = useState([])
 // let [selectedApartment] = apt.filter(value => value.name === selector)
  let seletedDefault = apt[1]

  useEffect(() => {
    getWhatsapp()
    getList()
    getAparment('','','')
  }, []);

  const getList = () => {
    axios.get(
      'https://e2pa3qvdb9.us-east-1.awsapprunner.com/aparment/',
      {
        headers: {
          // "Authorization": "Token " + this.userToken,
        }
      }
    ).then(function (response) {
      setApartments(response.data.filter(value => value.disponibilidad === true))
      // this.notify("tr", "success", "Evento Creado!");
      // this.getEvents()

    }.bind(this)).catch(function (error) {
      // this.toggleLoading();
      // this.handleError(error, 'clients');
    }.bind(this));
  } 

  const getWhatsapp = () => {
    axios.get(
      'https://e2pa3qvdb9.us-east-1.awsapprunner.com/info_page/1/',
      {
        headers: {
          // "Authorization": "Token " + this.userToken,
        }
      }
    ).then(function (response) {
      setWhatsapp(response.data.wa)
      // this.notify("tr", "success", "Evento Creado!");
      // this.getEvents()

    }.bind(this)).catch(function (error) {
      // this.toggleLoading();
      // this.handleError(error, 'clients');
    }.bind(this));
  }

  const getAparment = (type, level, view) => {
    const formData = new FormData();
    formData.append("type", type);
     formData.append("level", level);
    formData.append("view", view);
    axios.post(
      'https://e2pa3qvdb9.us-east-1.awsapprunner.com/aparment/search/',
      formData,
      {
        headers: {
          // "Authorization": "Token " + this.userToken,
        }
      }
    ).then(function (response) {
      setSelectapartment(response.data.filter(value => value.disponibilidad === true))
      // this.notify("tr", "success", "Evento Creado!");
      // this.getEvents()

    }.bind(this)).catch(function (error) {
      // this.toggleLoading();
      // this.handleError(error, 'clients');
    }.bind(this));
  }

  const getLevel = (type, level, view) => {
    const formData = new FormData();
    formData.append("type", type);
     formData.append("level", level);
    formData.append("view", view);
    axios.post(
      'https://e2pa3qvdb9.us-east-1.awsapprunner.com/aparment/search/',
      formData,
      {
        headers: {
          // "Authorization": "Token " + this.userToken,
        }
      }
    ).then(function (response) {
      setSelectapartment(response.data)
      // this.notify("tr", "success", "Evento Creado!");
      // this.getEvents()

    }.bind(this)).catch(function (error) {
      // this.toggleLoading();
      // this.handleError(error, 'clients');
    }.bind(this));
  }

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path='/' component={() => <ContentHome filter={filter} setFilter={setFilter} inicial={inicial} setSelector={setSelector} setBtnstyle={setBtnstyle} btnAptstyle={btnAptstyle} setAptBtnstyle={setAptBtnstyle} btnAptstyle3={btnAptstyle3} setbtnAptstyle3={setbtnAptstyle3} numberLevel={numberLevel} setNumberLevel={setNumberLevel} selectedLevel={selectedLevel} setSelectedLevel={setSelectedLevel} selectedType={selectedType} setSelectedType={setSelectedType} selectedView={selectedView} setSelectedView={setSelectedView} search={getAparment} search2={getLevel} bandera={bandera} setBandera={setBandera} whatsapp={whatsapp} />} />
        <Route exact path="/search" component={() => <ContentSearch filter={filter} setFilter={setFilter} inicial={inicial} selector={selector} setSelector={setSelector} apt={apt} selectedApartment={selectedApartment ? selectedApartment : seletedDefault} btnstyle={btnstyle} setBtnstyle={setBtnstyle} btnAptstyle={btnAptstyle} setNumberLevel={setNumberLevel} setAptBtnstyle={setAptBtnstyle} btnAptstyle3={btnAptstyle3} setbtnAptstyle3={setbtnAptstyle3} search={getAparment} selectedLevel={selectedLevel} setSelectedLevel={setSelectedLevel} selectedType={selectedType} setSelectedType={setSelectedType} search2={getLevel} selectedView={selectedView} setSelectedView={setSelectedView} bandera={bandera} setBandera={setBandera} whatsapp={whatsapp} />} />
        <Route exact path="/detail" component={() => <ContentDetail filter={filter} setFilter={setFilter} inicial={inicial} selector={selector} setSelector={setSelector} apt={apt} selectedApartment={selectedApartment} btnstyle={btnstyle} setBtnstyle={setBtnstyle} btnAptstyle={btnAptstyle} setAptBtnstyle={setAptBtnstyle} btnAptstyle3={btnAptstyle3} setNumberLevel={setNumberLevel} setbtnAptstyle3={setbtnAptstyle3} search={getAparment} bandera={bandera} setBandera={setBandera} whatsapp={whatsapp} getLevel={getLevel} setSelectapartment={setSelectapartment}/>} />
        <Route exact path="/levels" component={() => <ContentDetail2 filter={filter} setFilter={setFilter} inicial={inicial} selector={selector} setSelector={setSelector} apt={apt} selectedApartment={selectedApartment} btnstyle={btnstyle} setBtnstyle={setBtnstyle} btnAptstyle={btnAptstyle} setAptBtnstyle={setAptBtnstyle} btnAptstyle3={btnAptstyle3} setbtnAptstyle3={setbtnAptstyle3} numberLevel={numberLevel} setNumberLevel={setNumberLevel} search={getAparment} bandera={bandera} setBandera={setBandera} whatsapp={whatsapp} search2={getLevel}/>} />
      </Switch>
    </Suspense>)
}


export default App;
